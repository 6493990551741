import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { PortableTextComponent } from '/features/buildingBlocks/PortableTextComponent'
import { ContentColumns } from '/features/buildingBlocks/ContentColumns'

import styles from './Faq.css'

import iconArrowDown from '/images/icons/arrow-down.raw.svg'

export function Faq({ title, items, styleContext = undefined }) {
  return (
    <section data-x='accordion' className={styles.component} data-style-context={styleContext}>
      <ContainerXl>
        <ContentColumns
          renderSmallColumn={({ layoutClassName }) => title && <HeadingMd h={3} {...{ title, layoutClassName }} />}
          renderLargeColumn={({ layoutClassName }) => <Accordion {...{ items, layoutClassName }} />}
        />
      </ContainerXl>
    </section>
  )
}

function Accordion({ items, layoutClassName }) {
  const [activeItem, setActiveItem] = React.useState(null)

  return (
    <ul className={layoutClassName}>
      {items.map((item, i) => (
        <AccordionItem
          key={i}
          isActive={item === activeItem}
          onClick={handleClick}
          title={item.title}
          content={item.content}
          {...{ item }}
        />
      ))}
    </ul>
  )

  function handleClick(item) {
    setActiveItem(prevItem => ( prevItem !== item ? item : null))
  }
}

function AccordionItem({ item, title, content, isActive, onClick }) {
  return (
    <li className={styles.componentAccordionItem}>
      <ItemButton layoutClassName={styles.buttonLayout} {...{ item, title, isActive, onClick }} />
      <ItemContent {...{ content, isActive }} />
    </li>
  )
}

function ItemButton({ onClick, item, title, isActive, layoutClassName }) {
  return (
    <button
      type='button'
      onClick={() => onClick(item)}
      data-x={`click-to-${isActive ? 'close' : 'open'}-accordion-item`}
      className={cx(styles.componentItemButton, isActive && styles.isActive, layoutClassName)}
    >
      <span className={styles.questionTitle}>{title}</span>
      <span className={styles.iconContainer}>
        <Icon icon={iconArrowDown} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function ItemContent({ content, isActive }) {
  return (
    <div className={cx(styles.componentItemContent, isActive && styles.isActive)}>
      <PortableTextComponent value={content} />
    </div>
  )
}
